
import React from 'react';

const Key = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M2.33325 21.0001V24.5001C2.33325 25.2001 2.79992 25.6668 3.49992 25.6668H8.16659V22.1668H11.6666V18.6668H13.9999L15.6333 17.0334C17.2547 17.5983 19.0199 17.5961 20.64 17.0273C22.2601 16.4585 23.6392 15.3567 24.5517 13.9023C25.4642 12.4478 25.8561 10.7266 25.6632 9.02047C25.4704 7.31429 24.7042 5.72407 23.4901 4.50994C22.276 3.29581 20.6857 2.52965 18.9796 2.33681C17.2734 2.14397 15.5523 2.53585 14.0978 3.44835C12.6433 4.36086 11.5415 5.73996 10.9727 7.36005C10.4039 8.98013 10.4017 10.7453 10.9666 12.3668L2.33325 21.0001Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M19.2501 9.33341C19.5722 9.33341 19.8334 9.07225 19.8334 8.75008C19.8334 8.42792 19.5722 8.16675 19.2501 8.16675C18.9279 8.16675 18.6667 8.42792 18.6667 8.75008C18.6667 9.07225 18.9279 9.33341 19.2501 9.33341Z" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
      </g>
    </svg>
  );
};

export default Key;